import Stripe from 'stripe';
export class AfmStripeUserData {
  customers: Stripe.Customer[] = [];
  entitlements: Stripe.Entitlements.ActiveEntitlement[] = [];
  subscriptions: Stripe.Subscription[] = [];
  get activeSubscriptions (): Stripe.Subscription[] {
    return this.subscriptions.filter((s) => s.status === 'active' || s.status === 'trialing');
  }
  get showableSubscriptions (): Stripe.Subscription[] {
    return this.subscriptions.filter((s) => s.status !== 'incomplete_expired');
  }
  get entitlementsLookups(): string[] {
    return this.entitlements.map((e) => e.lookup_key);
  }
  get hasNonCanceledSubscriptions(): boolean {
    return this.subscriptions.filter(s => s.status !== 'canceled' && s.status !== 'incomplete_expired').filter(s => !s.cancel_at_period_end).length > 0;
  }
}
