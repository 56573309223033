import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Stripe } from 'stripe';
import { BehaviorSubject, Observable, filter, forkJoin, from, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../auth-service/auth-service.service';
import { AfmStripeUserData } from 'src/app/shared/models/afmStripeUserData.models';
import { getLimitedUseToken } from '@angular/fire/app-check';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AfmBeService {
  private baseUrl = 'https://api.albionfreemarket.com/be';
  // private baseUrl = 'http://localhost:3023';
  httpOptions: { headers: HttpHeaders } = { headers: new HttpHeaders() };
  afmStripeUserData: AfmStripeUserData = new AfmStripeUserData();
  private dataIsSet = new BehaviorSubject<boolean>(false);
  $userDataIsSet = this.dataIsSet.asObservable();
  private httpOptionsSet = new BehaviorSubject<boolean>(false);
  private productDataIsSet = new BehaviorSubject<boolean>(false);
  $prouctDataIsSet = this.productDataIsSet.asObservable();
  products: { product: Stripe.Product; prices: Stripe.Price[] }[] = [];

  // MARK: - Constructor
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.authService.idToken$.subscribe(async (token) => {
      if (token) {
        this.httpOptions = {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        };
        this.httpOptionsSet.next(true);
      } else {
        this.httpOptions = { headers: new HttpHeaders() };
        this.httpOptionsSet.next(false);
      }
    });

    this.authService.user$.subscribe(async (user) => {
      this.afmStripeUserData = new AfmStripeUserData();
      if (user) {
        this.updateAllUserData().then(() => {});
      }
    });

    this.getActiveProducts()
      .pipe(
        tap(() => {
          this.products = [];
          this.productDataIsSet.next(false);
        }),
        take(1)
      )
      .subscribe((products) => {
        this.products = products.filter((x) => x.prices.filter((x) => x.type === 'recurring').length > 0);
        this.products.forEach((p) => {
          p.prices.sort((a, b) => a.currency.localeCompare(b.currency));
        });
        this.productDataIsSet.next(true);
      });
  }

  // MARK: - Public Functions
  updateAllUserData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getCustomers()
        .pipe(
          take(1),
          switchMap((customers) => {
            if (customers) {
              return forkJoin([
                this.getAllSubscriptions(customers.map((c) => c.id)).pipe(take(1)),
                this.getEntitlements(customers.map((c) => c.id)).pipe(take(1)),
              ]);
            } else {
              return of(null);
            }
          })
        )
        .subscribe({
          next: () => {
            // console.log('All user data updated: ', this.afmStripeUserData);
            this.dataIsSet.next(true);
          },
          error: reject,
          complete: resolve,
        });
    });
  }

  updateCustomers(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getCustomers()
        .pipe(take(1))
        .subscribe({
          next: () => {
            // console.log('Customer updated: ', this.afmStripeUserData.customer);
          },
          error: reject,
          complete: resolve,
        });
    });
  }

  updateAllSubscriptions(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.afmStripeUserData.customers) {
        this.getAllSubscriptions(this.afmStripeUserData.customers.map((c) => c.id))
          .pipe(take(1))
          .subscribe({
            next: () => {
              // console.log('Active subscriptions updated: ', this.afmStripeUserData.activeSubscriptions);
            },
            error: reject,
            complete: resolve,
          });
      } else {
        resolve();
      }
    });
  }

  updateEntitlements(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.afmStripeUserData.customers) {
        this.getEntitlements(this.afmStripeUserData.customers.map((c) => c.id))
          .pipe(take(1))
          .subscribe({
            next: () => {
              // console.log('Entitlements updated: ', this.afmStripeUserData.entitlements);
            },
            error: reject,
            complete: resolve,
          });
      } else {
        resolve();
      }
    });
  }

  getLoadedProductById(productId: string): { product: Stripe.Product; prices: Stripe.Price[] } | undefined {
    return this.products.find((p) => p.product.id === productId);
  }

  createCheckout(priceId: string, token: string): Observable<Stripe.Checkout.Session> {
    return this.httpOptionsSet.pipe(
      filter((set) => set === true),
      switchMap(() => {
        const optionsWithToken = {
          ...this.httpOptions,
          headers: this.httpOptions.headers.append('Turnstile-Token', token),
        };
        return this.http.get<Stripe.Checkout.Session>(`${this.baseUrl}/api/checkout/${priceId}`, optionsWithToken);
      }),
      take(1)
    );
  }

  createPortalSession(customerId: string, token: string): Observable<Stripe.BillingPortal.Session> {
    return this.httpOptionsSet.pipe(
      filter((set) => set === true),
      switchMap(() => {
        const optionsWithToken = {
          ...this.httpOptions,
          headers: this.httpOptions.headers.append('Turnstile-Token', token),
        };
        return this.http.get<Stripe.BillingPortal.Session>(`${this.baseUrl}/api/portal/${customerId}`, optionsWithToken);
      }),
      take(1)
    );
  }

  getActiveProducts(): Observable<{ product: Stripe.Product; prices: Stripe.Price[] }[]> {
    let url = `${this.baseUrl}/api/products/active`;
    if (isPlatformServer(this.platformId)) {
      url = 'assets/ssg-files/active_products.json';
    }
    return this.http.get<{ product: Stripe.Product; prices: Stripe.Price[] }[]>(url).pipe(take(1));
  }

  // MARK: - Private Functions
  private getCustomers(): Observable<Stripe.Customer[]> {
    return this.httpOptionsSet.pipe(
      filter((set) => set === true),
      switchMap(() => this.http.get<Stripe.Customer[]>(`${this.baseUrl}/api/customers`, this.httpOptions)),
      tap((customers) => {
        this.afmStripeUserData.customers = customers;
        // console.log('Customers: ', customers);
      }),
      take(1)
    );
  }

  private getAllSubscriptions(customersIds: string[]): Observable<Stripe.Subscription[]> {
    return this.httpOptionsSet.pipe(
      filter((set) => set === true),
      switchMap(() => this.http.get<Stripe.Subscription[]>(`${this.baseUrl}/api/subscriptions/all?customersIds=${customersIds.join(',')}`, this.httpOptions)),
      tap((subscriptions) => {
        this.afmStripeUserData.subscriptions = subscriptions;
      }),
      take(1)
    );
  }

  private getEntitlements(customerIds: string[]): Observable<Stripe.Entitlements.ActiveEntitlement[]> {
    return this.httpOptionsSet.pipe(
      filter((set) => set === true),
      switchMap(() =>
        this.http.get<Stripe.Entitlements.ActiveEntitlement[]>(`${this.baseUrl}/api/entitlements?customersIds=${customerIds.join(',')}`, this.httpOptions)
      ),
      tap((entitlements) => {
        this.afmStripeUserData.entitlements = entitlements;
      }),
      take(1)
    );
  }
}
